var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "table" },
    [
      _c("div", { staticClass: "tr" }, [
        _vm.showTotals
          ? _c("div", { staticClass: "td manager" }, [
              _vm._v(
                " Currency - " +
                  _vm._s(_vm.portfolioBaseCurrency) +
                  " (" +
                  _vm._s(_vm.currencySymbol) +
                  ") "
              ),
            ])
          : _vm._e(),
      ]),
      _c("div", { staticClass: "td manager" }, [
        _vm._v(" Manager "),
        _vm.isPerformanceView
          ? _c("small", [
              _vm._v(
                "- % returns for " +
                  _vm._s(_vm.portfolioBaseCurrency) +
                  " values"
              ),
            ])
          : _vm._e(),
        _vm.isBenchmarkView
          ? _c("small", [
              _vm._v(
                "- % returns for " +
                  _vm._s(_vm.portfolioBaseCurrency) +
                  " values"
              ),
            ])
          : _vm._e(),
      ]),
      _vm.isAlternativePerformanceSettingsView
        ? _c("div", { staticClass: "tr" }, [_c("div", { staticClass: "td" })])
        : _vm._e(),
      _vm._l(_vm.managerListData, function (manager, index) {
        return _c(
          "div",
          { key: manager.name + manager.id + index, staticClass: "tr" },
          [
            _c(
              "div",
              {
                staticClass: "td max-height",
                class: { "in-focus": _vm.managerInFocus === index },
              },
              [
                _c("div", { staticClass: "d-flex" }, [
                  _c(
                    "div",
                    { staticClass: "manager-name" },
                    [
                      _c("font-awesome-icon", {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip",
                            value: _vm.getEntityNameByEntityTypeId(
                              manager.entityTypeId
                            ),
                            expression:
                              "getEntityNameByEntityTypeId(manager.entityTypeId)",
                          },
                        ],
                        staticClass: "fa-fw",
                        attrs: {
                          icon: [
                            "fad",
                            _vm.getIconByEntityTypeId(manager.entityTypeId),
                          ],
                        },
                      }),
                      _vm._v("  "),
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip",
                              value: manager.name,
                              expression: "manager.name",
                            },
                          ],
                        },
                        [
                          !_vm.isBenchmarkView && manager.parentEntityName
                            ? [
                                _vm._v(
                                  " " + _vm._s(manager.parentEntityName) + " — "
                                ),
                              ]
                            : _vm._e(),
                          _c("span", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.isBenchmarkView
                                  ? _vm.cropName(manager.name)
                                  : "<b>" + manager.name + "<b>"
                              ),
                            },
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  (_vm.isPerformanceView || _vm.isBenchmarkView) &&
                  !_vm.isAlternativePerformanceSettingsView
                    ? _c(
                        "div",
                        [
                          _vm.showPPPButton &&
                          manager.partPeriodPerformances &&
                          manager.partPeriodPerformances.length
                            ? _c(
                                "v-popover",
                                {
                                  attrs: {
                                    offset: "0",
                                    trigger: "hover",
                                    placement: "right",
                                  },
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      attrs: { type: "button" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openPPPModal(
                                            manager.id,
                                            manager.benchmarkId,
                                            manager.pppTickerId
                                          )
                                        },
                                      },
                                    },
                                    [
                                      manager.partPeriodPerformances &&
                                      manager.partPeriodPerformances.length
                                        ? _c("font-awesome-icon", {
                                            attrs: {
                                              icon: ["fal", "calendar-edit"],
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c("template", { slot: "popover" }, [
                                    _c(
                                      "table",
                                      { staticClass: "table" },
                                      [
                                        _c("thead", [
                                          _c("tr", { staticClass: "tr" }, [
                                            _c("th", [_vm._v("Part Period")]),
                                            _c("th", [_vm._v("Performance")]),
                                          ]),
                                        ]),
                                        _vm._l(
                                          manager.partPeriodPerformances,
                                          function (
                                            partPeriodPerformance,
                                            index
                                          ) {
                                            return _c(
                                              "tbody",
                                              {
                                                key:
                                                  partPeriodPerformance.performancePeriodTag +
                                                  index,
                                              },
                                              [
                                                _c(
                                                  "tr",
                                                  { staticClass: "tr" },
                                                  [
                                                    _c(
                                                      "td",
                                                      { staticClass: "td" },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            partPeriodPerformance.performancePeriodTag
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "td",
                                                      {
                                                        staticClass:
                                                          "td text-right",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            partPeriodPerformance.tickerValue
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ],
                                2
                              )
                            : _c(
                                "button",
                                {
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openPPPModal(
                                        manager.id,
                                        manager.benchmarkId,
                                        manager.pppTickerId
                                      )
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    directives: [
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip",
                                        value: _vm.buttonTitle,
                                        expression: "buttonTitle",
                                      },
                                    ],
                                    attrs: { icon: ["fal", "calendar-plus"] },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ]
            ),
          ]
        )
      }),
      _vm.showTotals
        ? _c("div", { staticClass: "tr" }, [
            _c("div", { staticClass: "td total" }, [_vm._v("Totals")]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }